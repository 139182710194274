@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 173 67% 39%;
    --primary-foreground: 0 0% 100%;

    --secondary: 270 100% 47%;
    --secondary-foreground: 0 0% 100%;

    --tertiary: 28 87% 62%;
    --tertiary-foreground: 0 0% 100%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 55%;
    --destructive-foreground: 0 0% 100%;

    --ring: 215 20.2% 65.1%;

    --border-radius: 0.25rem;
    --base-spacing: 0.5rem;

    --neutral-700: #434343;
    --neutral-600: #727272;
    --neutral-500: #a1a1a1;
    --neutral-400: #141414;
    --neutral-300: #d0d0d0;
    --neutral-200: #e0e0e0;
    --neutral-100: #f2f2f2;

    --success-800: #072713;
    --success-700: #0e4f26;
    --success-600: #147638;
    --success-500: #1b9e4b;
    --success-400: #22c55e;
    --success-300: #4ed17e;
    --success-200: #7adc9e;
    --success-100: #a7e8bf;
    --success-50: #d3f3df;

    --error-800: #250505;
    --error-700: #4a0a0a;
    --error-600: #701010;
    --error-500: #951515;
    --error-400: #ba1a1a;
    --error-300: #c84848;
    --error-200: #d67676;
    --error-100: #e3a3a3;
    --error-50: #f1d1d1;
    --error-10: #f9ebeb;

    --tertiary-800: #301f0f;
    --tertiary-700: #613d1e;
    --tertiary-600: #915c2c;
    --tertiary-500: #c27a3b;
    --tertiary-400: #f2994a;
    --tertiary-300: #f5ad6e;
    --tertiary-200: #f7c292;
    --tertiary-100: #fad6b7;
    --tertiary-50: #fcebdb;
    --tertiary-10: #fdf2e7;

    --secondary-800: #180030;
    --secondary-700: #30005f;
    --secondary-600: #47008f;
    --secondary-500: #5f00be;
    --secondary-400: #7700ee;
    --secondary-300: #9233f1;
    --secondary-200: #ad66f5;
    --secondary-100: #c999f8;
    --secondary-50: #e4ccfc;
    --secondary-10: #f2e7fe;

    --primary-800: #07211e;
    --primary-700: #0d423c;
    --primary-600: #14645a;
    --primary-500: #1a8578;
    --primary-400: #21a696;
    --primary-300: #4db8ab;
    --primary-200: #7acac0;
    --primary-100: #a6dbd5;
    --primary-50: #d3edea;
    --primary-10: #edf8f6;

    --white: #ffffff;
    --black: #000000;
    --midnight: #141414;
    --midnight-60: #14141499;
    --background: #f9fbfb;
    --logo: #0bd6c2;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 217.2 32.6% 17.5%;
  }
}

@layer base {
  * {
    @apply border-neutral-200;
  }
  body {
    @apply bg-background text-midnight;
  }
}
